import React from 'react'

const ImageItems = ({item}) => {
  return (
    <div className="image__card" key={item.id}>
      <img src={item.image} alt="" className='image__img' />
      <h3 className="image__title">{item.title}</h3>

      {/* <a href="#" className="image__button">
        Learn More <i className="bx bx-right-arrow-alt image__button-icon"></i>
      </a> */}


    </div>
  )
}

export default ImageItems