import React, { useEffect, useState } from 'react'
import { projectsData } from './Data'
import { projectsNav } from './Data'
import ImageItems from './ImageItems'

const Images = () => {
  const [item, setItem] = useState({name: 'hospitality'});
  const [projects, setProjects] = useState([]);
  const [active, setActive] = useState(0);

  useEffect (() => {
    if (item.name === 'all') {
      setProjects(projectsData);
    }

    else {
      const newProjects = projectsData.filter((project) => {
        return project.category.toLowerCase() === item.name;
      });
      setProjects(newProjects);
    }
  }, [item]);

  const handleClick = (e, index) => {
    setItem({name: e.target.textContent.toLowerCase()});
    setActive(index);
  };
  return (
    <div>
      <div className="container">
        <div className="image__filters">
          {projectsNav.map((item, index) => {
            return (
              <span 
                key={index}
                onClick={(e) => {
                  handleClick(e, index);
                }} 
                className={`${active === index ? 'active-image' : "" } 
                  image__item`} 
                >{item.name}
              </span>
            )
          })}
        </div>


        <div className="image__container container grid">

          {projects.map((item) => {
            return <ImageItems item={item} key={item} />
          })}
        

        </div>
      </div>
    </div>
  )
}

export default Images