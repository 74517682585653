import HeroSlider, { Overlay, Slide, MenuNav, SideNav, ButtonsNav } from "hero-slider";
import Wrapper from "../herocomponents/Wrapper";
import './hero.css'


const jazeeraPalaceHotelSomalia = "https://i0.wp.com/somaliainvestor.so/wp-content/uploads/2020/05/Jazeera-Palace-Hotel.-scaled.jpg?w=2560&ssl=1";
// const jazeeraPalaceHotelSomalia = "https://images2.imgbox.com/47/e2/afY5QvqF_o.jpeg";
// const jazeeraLogisticsAfrica = "https://images2.imgbox.com/01/87/kBPYbpIv_o.jpg";
// const jazeeraLogisticsAfrica = "https://images2.imgbox.com/cb/7f/K1jo6RWH_o.jpg";
const jazeeraLogisticsAfrica = "https://images2.imgbox.com/cb/bf/VT2jMJPT_o.jpg";
// const jazeeraLogisticsAfrica = "https://images2.imgbox.com/b5/cd/AGV1Yfwc_o.jpg";
// const jazeeraConstructionAfrica = "https://images2.imgbox.com/a1/ce/0ZPuBcWV_o.jpg";
// const jazeeraConstructionAfrica = "https://images2.imgbox.com/ac/3e/y9dxfI0r_o.jpg";
const jazeeraConstructionAfrica = "https://images2.imgbox.com/c6/21/RJLEVmgI_o.jpg";
// const jazeeraConstructionAfrica = "https://images2.imgbox.com/5d/4c/tvJVuBlT_o.jpg";
// const jazeeraConstructionAfrica = "https://images2.imgbox.com/5d/4c/tvJVuBlT_o.jpg";
const jazeeraTradingAfrica = "https://images.unsplash.com/photo-1493946740644-2d8a1f1a6aff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1790&q=80";
const jazeeraSecurityAfrica = "https://images2.imgbox.com/24/bf/wrEDammq_o.jpg";
const jazeeraEnergyAfrica = "https://images.unsplash.com/photo-1473341304170-971dccb5ac1e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80";
// const jazeeraEnergyAfrica = "https://images2.imgbox.com/62/3b/ZtvG0qVZ_o.jpg";


const Hero = () => {
  return (

     <section className="hero section" id="hero">

      <HeroSlider
      // slidingAnimation="fade"

      slidingAnimation="left_to_right"
      height={"70vh"}
      width={"100vw"}

      autoplay
      // controller={{
        
      //   initialSlide: 1,
        // slidingDuration: 200
      //   slidingDelay: 50,
      //   onSliding: (nextSlide) =>
      //     console.debug("onSliding(nextSlide): ", nextSlide),
      //   onBeforeSliding: (previousSlide, nextSlide) =>
      //     console.debug(
      //       "onBeforeSliding(previousSlide, nextSlide): ",
      //       previousSlide,
      //       nextSlide
      //     ),
      //   onAfterSliding: (nextSlide) =>
      //     console.debug("onAfterSliding(nextSlide): ", nextSlide)
      // }}
      orientation="horizontal"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) =>
        console.log("onBeforeChange", previousSlide, nextSlide)
      }
      onChange={nextSlide => console.log("onChange", nextSlide)}
      onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
      style={{
        backgroundColor: "#000"
      }}
      settings={{
        slidingDuration: 200,
        slidingDelay: 100,
        shouldAutoplay: true,
        autoplayDuration: 300,
        // sliderColor: '#EF3340',
        // navbarStyle: {
        //   color: '#4c4c4c',
        //   // activeColor: '#EF3340',
        // }

       }}
    >
      <Overlay>
        <Wrapper>
          <div className="hero__text__container">

          <h2 className='hero__title'>Jazeera Group</h2>
          <h3 className="hero__subtitle">
          Jazeera Group of Companies is a business consortium operating in the East Africa region. <br />
          </h3>

           {/* <p className="hero__paragraph">
            With a stellar reputation for delivering excellent service for 15+ years, Jazeera Group prides itself on promoting sustainability, safety, and success across key industries in the region.
            </p> */}
          </div>
        </Wrapper>
      </Overlay>

      <Slide
        // shouldRenderMask
        
        label="Hospitality"
        background={{
          backgroundImageSrc: jazeeraPalaceHotelSomalia
        }}
      />

      <Slide
        // shouldRenderMask
        label="Energy"
        background={{
          backgroundImageSrc: jazeeraEnergyAfrica
        }}
      />
      <Slide
        // shouldRenderMask
        label="Logistics"
        background={{
          backgroundImageSrc: jazeeraLogisticsAfrica
        }}
      />

      <Slide
        // shouldRenderMask
        label="Construction and Engineering"
        background={{
          backgroundImageSrc: jazeeraConstructionAfrica
        }}
      />

      <Slide
        // shouldRenderMask
        label="Security"
        background={{
          backgroundImageSrc: jazeeraSecurityAfrica
        }}
      />
      <Slide
        // shouldRenderMask
        label="Trading"
        background={{
          backgroundImageSrc: jazeeraTradingAfrica
        }}
      />
 
      <MenuNav/>
      {/* <SideNav
      /> */}

    </HeroSlider>

    
</section>
  )
}

export default Hero



// create a stylish minimalist hero section with an animated image carousel using framer motion


















// import React from 'react'
// import Data from './Data'
// import './home.css'
// import ScrollDown from './ScrollDown'
// import Social from './Social'

// const Home = () => {
//   return (
//     <section className="home section" id="home">
//       <div className="home__container container grid">
//         <div className="home__content grid">
//           <Social />

//           <div className="home__img"></div>
//           <Data />


//         </div>
//           <ScrollDown />
//       </div>
//     </section>
//   )
// }

// export default Home