import React from 'react'
import {motion} from 'framer-motion'
import './loader.css'



const LoaderSVG = () => {
  return (
    <div className='loader__svg__container'>
      <div className="loader__div2">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100pt" height="100pt" viewBox="0 0 269.000000 269.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,269.000000) scale(0.100000,-0.100000)"
        fill="#ef3340" stroke="none">
        <motion.path initial={{ opacity:0, y:-2000}} animate={{ opacity:1, y:0}} transition={{ delay:0.8, duration:0.8, repeat: Infinity, repeatDelay: 2}} d="M1630 1834 l0 -856 53 30 c28 16 92 51 140 77 l88 47 -40 74 c-23 43
        -35 74 -28 74 11 0 276 -65 304 -75 9 -3 4 -30 -21 -97 -62 -170 -77 -208 -84
        -208 -4 0 -20 25 -37 56 -16 30 -35 59 -40 64 -6 5 -75 -27 -170 -80 l-160
        -88 -3 -367 -2 -366 22 7 c124 39 292 148 408 264 184 184 311 431 369 717 20
        97 21 133 21 842 l0 741 -410 0 -410 0 0 -856z"/>
        </g>
        <g transform="translate(0.000000,269.000000) scale(0.100000,-0.100000)"
        fill="#1b1b1b" stroke="none">
        <motion.path initial={{ opacity:0, y:-2000}} animate={{ opacity:1, y:0}} transition={{delay:0.6, duration:0.8, repeat: Infinity, repeatDelay: 2}}  d="M1220 1035 c0 -118 2 -215 5 -215 2 0 54 27 115 61 l111 61 -3 151
        -3 152 -112 3 -113 3 0 -216z"/>
        <motion.path initial={{ opacity:0, y:-2000}} animate={{ opacity:1, y:0}} transition={{delay:0.4, duration:0.8 , repeat: Infinity, repeatDelay: 2}}d="M830 785 l0 -185 53 29 c28 16 79 45 112 63 l60 34 3 122 3 122 -116
        0 -115 0 0 -185z"/>
        <motion.path initial={{ opacity:0, y:-2000}} animate={{ opacity:1, y:0}} transition={{delay:0.2, duration:0.8, repeat: Infinity, repeatDelay: 2}} d="M450 570 c0 -93 3 -170 8 -170 4 0 54 26 112 58 l105 59 0 109 0 109
        -112 3 -113 3 0 -171z"/>
        <motion.path initial={{ opacity:0, y:-2000}} animate={{ opacity:1, y:0}} transition={{delay:0.6, duration:0.8, repeat: Infinity, repeatDelay: 2}} d="M1323 632 l-103 -57 0 -247 0 -248 115 0 115 0 0 305 c0 238 -3 305
        -12 304 -7 0 -59 -26 -115 -57z"/>
        <motion.path initial={{ opacity:0, y:-2000}} animate={{ opacity:1, y:0}} transition={{delay:0.4, duration:0.8, repeat: Infinity, repeatDelay: 2}} d="M940 421 l-105 -59 -3 -141 -3 -141 116 0 115 0 0 200 c0 110 -3 200
        -7 200 -5 -1 -55 -27 -113 -59z"/>
        <motion.path initial={{ opacity:0, y:-2000}} animate={{ opacity:1, y:0}} transition={{delay:0.2, duration:0.8, repeat: Infinity, repeatDelay: 2}} d="M560 212 c-101 -57 -105 -60 -108 -95 l-3 -37 115 0 116 0 0 95 c0
        52 -3 95 -7 95 -5 0 -55 -26 -113 -58z"/>
        </g>
        </svg>
      </div>
    </div>
  )
}

export default LoaderSVG