import './App.css';
// import About from './components/about/About';
import Achievements from './components/achievements/Achievements';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
// import Home from './components/home/Home';
import Gallery from './components/gallery/Gallery';
// import Industries from './components/industries/Industries';
import ScrollUp from './components/scrollup/ScrollUp';
import Services from './components/services/Services';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import LoaderSVG from './components/loader/LoaderSVG';
import Hero from './components/hero/Hero';

const Home = lazy(() => (import('./components/home/Home')));
const About = lazy(() => import('./components/about/About'));

// import Testimonials from './components/testimonials/Testimonials';

// const LoadTimer = React.lazy(() => {
//   return new Promise(resolve => {
//     setTimeout(() => {
//       resolve(import('./components/loader/LoadTimer'));

//     }, 2000)
// });
// })


function App() {
  const [loadings, setLoadings] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoadings(false), 4500)
  }, [])

  return (
    <>
    {loadings === false ? (
      <>
          {/* <Suspense fallback={<LoaderSVG />}> */}
          <Header />
          <main className='main'>
            {/* <Home /> */}
            <Hero />
          {/* <LoadTimer /> */}
            <About />

          <Services />
          {/* <Industries /> */}
          <Achievements />
          <Gallery />
          {/* <Testimonials /> */}
          <Contact />
        </main>
        <Footer />
        <ScrollUp />
        {/* </Suspense> */}
    </>
      ) : (
      <LoaderSVG />
    )} 
    </>
  );
}

export default App;

// async function delayForDemo(promise) {
//   return new Promise(resolve => {
//     setTimeout(resolve, 6000);
//   }).then(() => promise);
// }