import React, { useState } from 'react'
import './achievements.css'
import {motion} from 'framer-motion'
import { TrophyIcon } from '@heroicons/react/24/outline' 


const Achievements = () => {

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  }

  
  return (
    <section className="achievements section" id='achievements'>
       <motion.h2 className="section__title"
        initial={{y:20, opacity:0}}
        whileInView={{y:0, opacity:1}}
        viewport={{once:true}}
        transition={{duration: .5, delay:0.5}}
      >
        Achievements
      </motion.h2>
        <motion.span 
          className="section__subtitle"  
          initial={{y:200, opacity:0}}
          whileInView={{y:0, opacity:1}}
          viewport={{once:true}}
          transition={{duration: 0.7, delay:0.75}}
        >
          What we've Done</motion.span>

    <div className="achievements__container container">
      <div className="achievements__tabs">
        <div className={ toggleState === 1 ? "achievements__button achievements__active button--flex" : "achievements__button button--flex"} onClick={() => toggleTab(1)}>
          <TrophyIcon className="achievements__icon__comp" />
          {/* <i className="uil uil-trophy achievements__icon"></i> Milestones */}
        </div>
        {/* <div className={ toggleState === 2 ? "achievements__button achievements__active button--flex" : "achievements__button button--flex"} onClick={() => toggleTab(2)}>
          <i className="uil uil-ship achievements__icon"></i> Logistics
        </div> */}
      </div>

      <div className="achievements__sections">

        <div className={ toggleState === 1 ? "achievements__content achievements__content-active" :  "achievements__content"}>

          <div className="achievements__data">
            <div></div>
            <div>
              <span className="achievements__rounder"></span>
              <span className="achievements__line"></span>
            </div>
            <div>
              <h3 className="achievements__title">Jazeera Group</h3>
              <span className="achievements__subtitle">Started in 2008</span>
              {/* <div className="achievements__calendar">
                <i className="uil uil-calendar-alt"></i>2008
              </div> */}
            </div>

            
          </div>

          <div className="achievements__data">
            <div>
              <h3 className="achievements__title">Jazeera Logistics</h3>
              <span className="achievements__subtitle">Started in 2010</span>
              {/* <div className="achievements__calendar">
                <i className="uil uil-calendar-alt"></i>2010 - Present
              </div> */}
            </div>

            <div>
              <span className="achievements__rounder"></span>
              <span className="achievements__line"></span>
            </div>
          </div>

          <div className="achievements__data">
            <div></div>
            <div>
              <span className="achievements__rounder"></span>
              <span className="achievements__line"></span>
            </div>
            <div>
            <h3 className="achievements__title">Jazeera Hotel</h3>
              <span className="achievements__subtitle">Opened in 2012</span>
              {/* <div className="achievements__calendar">
                <i className="uil uil-calendar-alt"></i>2019
              </div> */}
            </div>

            
          </div>

          <div className="achievements__data">
            <div>

              <h3 className="achievements__title">Jazeera Construction</h3>
              <span className="achievements__subtitle">Grew in 2015</span>
              {/* <div className="achievements__calendar">
                <i className="uil uil-calendar-alt"></i>2021
              </div> */}
            </div>

            {/* <div>
              <span className="achievements__rounder"></span>
              <span className="achievements__line"></span>
            </div> */}
          </div>



        </div>

        

        <div className={ toggleState === 2 ? "achievements__content achievements__content-active" :  "achievements__content"}>

          <div className="achievements__data">
            <div></div>
            <div>
              <span className="achievements__rounder"></span>
              <span className="achievements__line"></span>
            </div>
            <div>
              <h3 className="achievements__title">Incorporated</h3>
              <span className="achievements__subtitle">Registration</span>
              <div className="achievements__calendar">
                <i className="uil uil-calendar-alt"></i>2010
              </div>
            </div>

            
          </div>

          <div className="achievements__data">
            <div>
              <h3 className="achievements__title">International Cargo</h3>
              <span className="achievements__subtitle">Global</span>
              <div className="achievements__calendar">
                <i className="uil uil-calendar-alt"></i>2014 - Present
              </div>
            </div>

            <div>
              <span className="achievements__rounder"></span>
              <span className="achievements__line"></span>
            </div>
          </div>

          <div className="achievements__data">
            <div></div>
            <div>
              <span className="achievements__rounder"></span>
              <span className="achievements__line"></span>
            </div>
            <div>
              <h3 className="achievements__title">Warehousing</h3>
              <span className="achievements__subtitle">Storage</span>
              <div className="achievements__calendar">
                <i className="uil uil-calendar-alt"></i>2018 - Present
              </div>
            </div>

            
          </div>




        </div>


      </div>
    </div>
    </section>
  )
}

export default Achievements