import Construct1 from '../../assets/images/jazeeraconstruction.jpg'
import Construct2 from '../../assets/images/constructionwork.jpg'
import Hotel2 from '../../assets/images/restaurants.jpg'
import Logistics2 from '../../assets/images/smallship.jpg'
import Hotel1 from '../../assets/images/hotel.jpg'
import Security1 from '../../assets/images/camera.jpg'
import Security2 from '../../assets/images/security.jpg'
import Trading1 from '../../assets/images/trading.jpg'
import Trading2 from '../../assets/images/trading2.jpg'


export const projectsData = [
  {
    id: 1,
    image: Hotel1,
    title: "Jazeera Palace",
    category: "hospitality",
    
  },
  {
    id: 2,
    image: Hotel2,
    title: "Restaurants",
    category: "hospitality",
  },
  {
    id: 3,
    image: Trading2,
    title: "Commodities",
    category: "trading",
    
  },
  {
    id: 4,
    image: Construct1,
    title: "Jazeera Construction",
    category: "construction",
    
  },
  {
    id: 5,
    image: Construct2,
    title: "Reconstruction",
    category: "construction",
    
  },
  {
    id: 6,
    image: Security1,
    title: "High Tech Security",
    category: "security",
  },

  {
    id: 7,
    image: Security2,
    title: "Armed Security",
    category: "security",
  },
  {
    id: 8,
    image: Trading1,
    title: "Trading",
    category: "trading",
  },
  {
    id: 9,
    image: Logistics2,
    title: "Logistics",
    category: "logistics",
  },
];


export const projectsNav = [
  
   
  {
    name: 'hospitality',
  },

  {
    name: 'construction',
  },
  
  {
    name: 'security',
  },
  
  {
    name: 'logistics',
  },
  {
    name: 'trading',
  },

  {
    name: 'all',
  },
  
  
]