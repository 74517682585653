import React, { useState } from 'react'
import './services.css'
import LogisticsImg from '../../assets/images/shipping.jpg'
import HospitalityImg from '../../assets/images/hotel.jpg'
import ConstructionImg from '../../assets/images/jazeeraconstruction.jpg'
import EnergyImg from '../../assets/images/energy.jpg'
import TradingImg from '../../assets/images/trading2.jpg'
import SecurityImg from '../../assets/images/camera.jpg'
import {motion} from 'framer-motion'
import { ArrowRightIcon, BoltIcon, CheckBadgeIcon, GlobeEuropeAfricaIcon, HomeModernIcon, PresentationChartLineIcon, ShieldCheckIcon } from '@heroicons/react/24/outline'


const Services = () => {

  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  }


  return (
    <section className="services section" id="services">
       <motion.h2 className="section__title"
        initial={{y:20, opacity:0}}
        whileInView={{y:0, opacity:1}}
        viewport={{once:true}}
        transition={{duration: .5, delay:0.5}}
      >
        Industries
      </motion.h2>
        <motion.span 
          className="section__subtitle"  
          initial={{y:200, opacity:0}}
          whileInView={{y:0, opacity:1}}
          viewport={{once:true}}
          transition={{duration: 0.7, delay:0.75}}
        >
          What we Do</motion.span>
  
    <div className="services__container container grid">
      <div className="services__content">
        <div>
        <img src={LogisticsImg} alt="" className="service__img" />
          <div className='services__wordBox'>
            <GlobeEuropeAfricaIcon className='services__icon__comp' />
            <h3 className="services__title">Logistics</h3>
            <span className="services__button" onClick={() => toggleTab(1) }>View More <ArrowRightIcon className=" services__button-icon"/></span>
          </div>
        </div>


        <div className={toggleState === 1 ? "services__modal active-modal" : 'services__modal' }>
          <div className="services__modal-content">
            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <h3 className="services__modal-title">Logistics</h3>

            <p className="services__modal-description">Jazeera Logistics is a leading provider of:</p>
            <ul className="services__modal-services grid">

              <li className="services__modal-service">
                {/* <CheckBadgeIcon className='services__modal__icon__comp' /> */}
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Transportation</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Warehousing</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Supply-chain Management</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Fulfillment</p>
              </li>
              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Freight Forwarding</p>
              </li>
              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Customs Clearance</p>
              </li>


            </ul>
          </div>

        </div>
      </div>
      <div className="services__content">
        <div>
          <img src={HospitalityImg} alt="" className="service__img" />
          <div className="services__wordBox">
            <HomeModernIcon className='services__icon__comp' />
            <h3 className="services__title">Hospitality</h3>
            <span className="services__button"  onClick={() => toggleTab(2)} >View More  <ArrowRightIcon className=" services__button-icon"/></span>
          </div>
        </div>


        <div className={toggleState === 2 ? "services__modal active-modal" : 'services__modal' }>
          <div className="services__modal-content">
            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <h3 className="services__modal-title">Hotel and Restaurants</h3>

                <p className="services__modal-description">Jazeera Palace Hotel is situated near Aden Abdulle International Airport and= accommodates foreign visitors, diplomats, business owners, and government officials. Additionally, we have state-of-the-art conference and meeting rooms </p>
            {/* <p className="services__modal-description">Jazeera Hospitality caters to a variety of hospitality needs such as: </p> */}
            <ul className="services__modal-services grid">

              {/* <li className="services__modal-service">
                <i className="uil uil-building services__modal-icon"></i> */}
                {/* <p className="services__modal-info">Jazeera Palace Hotel in Mogadishu</p>  */}
                {/* <p className="services__modal-info">Jazeera Palace Hotel accommodates foreign visitors, diplomats, business owners, and government officials. Additionally, we have state-of-the-art conference and meeting rooms </p>
              </li> */}

              {/* <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Restaurants in Somalia</p>
              </li> */}

              <li className="services__modal-service">
                <i className="uil uil-utensils services__modal-icon"></i>
                <p className="services__modal-info">Restaurant Franchise in Nairobi</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-clipboard services__modal-icon"></i>
                <p className="services__modal-info">Restaurant and Hotel Management</p>
              </li>


            </ul>
          </div>

        </div>
      </div>

      
      <div className="services__content">
        <div>
        <img src={ConstructionImg} alt="" className="service__img" />
        <div className="services__wordBox">
          <i className="uil uil-constructor services__icon"></i>
          <h3 className="services__title">Construction</h3>
          <span className="services__button"  onClick={() => toggleTab(4) }>View More  <ArrowRightIcon className=" services__button-icon"/></span>
          </div>
        </div>


        <div className={toggleState === 4 ? "services__modal active-modal" : 'services__modal' }>
          <div className="services__modal-content">
            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <h3 className="services__modal-title">Jazeera Construction and Engineering</h3>

            <p className="services__modal-description">Jazeera Construction and Engineering delivers high quality service while managing construction projects and provides support from design to delivery. <br></br>  <br></br>  We deploy world class Engineers while also engaging local labor markets in our areas of operation. </p>
            <ul className="services__modal-services grid">

              {/* <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Service 1</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Service 2</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Service 3</p>
              </li> */}



            </ul>
          </div>

        </div>
      </div>

      <div className="services__content">
        <div>
        <img src={EnergyImg} alt="" className="service__img" />
        <div className="services__wordBox">
          {/* <BoltIcon className='services__icon__comp' /> */}
          <i className="uil uil-bolt services__icon"></i>
          <h3 className="services__title">Energy</h3>
          <span className="services__button"  onClick={() => toggleTab(5) }>View More <ArrowRightIcon className=" services__button-icon"/></span>
        </div>
        </div>


        <div className={toggleState === 5 ? "services__modal active-modal" : 'services__modal' }>
          <div className="services__modal-content">
            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <h3 className="services__modal-title">Jazeera Energy</h3>

            <p className="services__modal-description">Jazeera Energy provides evidence-based and cost- effective energy solutions in commercial, industrial, and residential environments. <br /> <br /> As a rapidly growing energy company in East Africa, Jazeera Energy is committed to promoting long-term sustainability and efficiency for all energy needs in the public and private sectors.</p>
            <ul className="services__modal-services grid">

            

            </ul>
          </div>

        </div>
      </div>

      <div className="services__content">
        <div>
          <img src={TradingImg} alt="" className="service__img" />
          <div className="services__wordBox">
            <PresentationChartLineIcon className='services__icon__comp' />
            <h3 className="services__title">Trading</h3>
            <span className="services__button"  onClick={() => toggleTab(6) }>View More <ArrowRightIcon className=" services__button-icon"/></span>
          </div>
        </div>


        <div className={toggleState === 6 ? "services__modal active-modal" : 'services__modal' }>
          <div className="services__modal-content">
            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>

            <h3 className="services__modal-title">Jazeera Trading</h3>

            <p className="services__modal-description">We specialize in sourcing suppliers and buyers, negotiating prices and terms of sale, accessing capital, managing logistics, and navigating customs processes.</p>
            <ul className="services__modal-services grid">



            </ul>
          </div>

        </div>
      </div>

      <div className="services__content">
        <div>
          <img src={SecurityImg} alt="" className="service__img" />
          <div className="services__wordBox">
            <ShieldCheckIcon className='services__icon__comp' />
            <h3 className="services__title">Security</h3>
            <span className="services__button"  onClick={() => toggleTab(3) }>View More  <ArrowRightIcon className=" services__button-icon"/></span>
          </div>
        </div>


        <div className={toggleState === 3 ? "services__modal active-modal" : 'services__modal' }>
          <div className="services__modal-content">
            <i onClick={() => toggleTab(0)} className="uil uil-times services__modal-close"></i>
            <h3 className="services__modal-title">Security</h3>

            <p className="services__modal-description">Jazeera Security keeps companies, personnel, and physical assets safe as you carry out your business in Somalia. <br /> <br /> Jazeera Security knows how to safely navigate the challenging environs in Mogadishu and throughout Somalia.</p>
            <ul className="services__modal-services grid">

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Company Security</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Personnel Security</p>
              </li>

              <li className="services__modal-service">
                <i className="uil uil-check-circle services__modal-icon"></i>
                <p className="services__modal-info">Asset Security</p>
              </li>



            </ul>
          </div>

        </div>
      </div>
    </div>
    </section>
  )
}

export default Services