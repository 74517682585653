import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Jazeera Group</h1>
          <div className="footer__links">
            <ul className="footer__list">
              <li>
                <a href="#about" className="footer__link">About</a>
              </li>
              <li>
                <a href="#services" className="footer__link">Industries</a>
              </li>
              <li>
                <a href="#achievements" className="footer__link">Achievements</a>
              </li>
              <li>
                <a href="#contact" className="footer__link">Contact</a>
              </li> 
          </ul>
          </div>

        <div className="footer__groups">

        <div className="footer__address">
            <h4 className="address__title">Nairobi Office</h4>
            <span className="address__details">Kunde Rd, Lavington  <br /> <br/> ( +254 ) 20 263 4444 </span>
            {/* <span className="address__details">+254 20 263 4444</span> */}
          </div>


          <div className="footer__address">
            <h4 className="address__title">Mogadishu Office</h4>
            <span className="address__details">Airport Rd, Jazeera Hotel <br /> <br/>( +252 ) 616 460 045</span>
            {/* <span className="address__details">+252 616 460 045</span> */}
          </div>
        </div>

        {/* <div className="footer__social">
          <a href="https://www.facebook.com/" className="footer__social-link" target='_blank'>
            <i className='uil uil-facebook-f'></i>
          </a> 
          
          <a href="https://www.twitter.com/" className="footer__social-link" target='_blank'>
            <i className='uil uil-twitter'></i>

          </a>

          <a href="https://www.instagram.com/" className="footer__social-link" target='_blank'>
            <i className='uil uil--instagram'></i>
          </a>
        </div> */}

        <span className='footer__copy'>&#169; Jazeera Group of Companies. All rights reserved  </span>
      </div>
    </footer>
  )
}

export default Footer