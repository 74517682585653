import React, { useState } from 'react'
import './header.css'
import Logo from '../../assets/jlogo1.jpg'
import { Bars3Icon, BriefcaseIcon, EnvelopeIcon, HomeIcon, PhotoIcon, TrophyIcon, UserGroupIcon } from '@heroicons/react/24/outline' 


const Header = () => {
  /* <!-- ===============  Change background  header  =================== --> */
  window.addEventListener('scroll', function() {
    const header = document.querySelector(".header");
    // when the scroll is higher than 200 viewport height, add scrollheader class to a tag with the header class
    if (this.scrollY >= 80) header.classList.add('scroll-header');

    else header.classList.remove('scroll-header');
  })
  /* <!-- =============== TOGGLE MENU  =================== --> */

  const [Toggle, showMenu ] = useState(false);
  const [activeNav, setActiveNav] = useState('#home');

  return (
    <header className="header">
      <nav className="nav">
        <a href="#home" className="nav__logo">
        <img src={Logo} alt="" className="about__img" />
        </a>
        <div className={Toggle ? 'nav__menu show-menu' : 'nav__menu'}>
          <ul className="nav__list grid">
            
            <li className="nav__item">
              <a href="#home" onClick={() => setActiveNav('#home')} className={activeNav === '#home' ? "nav__link active-link" : "nav__link"}>
            
                <HomeIcon className='nav__icon__comp' />
                

                Home
              </a>
            </li>
            
            <li className="nav__item">
              <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? "nav__link active-link" : "nav__link"}>
                  <UserGroupIcon  className='nav__icon__comp' />
                About
              </a>
            </li>
            
            <li className="nav__item">
              <a href="#services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? "nav__link active-link" : "nav__link"}>
                <BriefcaseIcon  className='nav__icon__comp' />
                Industries
              </a>
            </li>
            
            <li className="nav__item">
              <a href="#achievements" onClick={() => setActiveNav('#achievements')} className={activeNav === '#achievements' ? "nav__link active-link" : "nav__link"}>
               <TrophyIcon  className='nav__icon__comp' />
               Achievements
              </a>
            </li>

            <li className="nav__item">
              <a href="#projects" onClick={() => setActiveNav('#projects')} className={activeNav === '#projects' ? "nav__link active-link" : "nav__link"}>
              <PhotoIcon  className='nav__icon__comp' />
              Gallery
              </a>
            </li>

            <li className="nav__item">
              <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? "nav__link active-link" : "nav__link"}>
                <EnvelopeIcon  className='nav__icon__comp' />
                Contact
              </a>
            </li>

            {/* <li className="nav__item">
              <a href="" className="nav__link">
                <i className="uil uil- nav__icon">
                </i>
              </a>
            </li> */}

          </ul>

          <i className="uil uil-times nav__close"  onClick={() => showMenu(!Toggle)}></i>

        </div>

        <div className="nav__toggle " onClick={() => showMenu(!Toggle)}>
            {/* <Bars3Icon className="h-6 w-6" /> */}

            <Bars3Icon className='menu__icon' />

          {/* <i className="uil uil-bars menu__icon"></i> */}
        </div>
      </nav>
    </header>
  )
}

export default Header