import React from 'react'
import './gallery.css'
import Images from './Images'
import {motion} from 'framer-motion'

const Gallery = () => {
  return (
   <section className="gallery section" id='projects'>
     <motion.h2 className="section__title"
        initial={{y:20, opacity:0}}
        whileInView={{y:0, opacity:1}}
        viewport={{once:true}}
        transition={{duration: .5, delay:0.5}}
      >
        Gallery
      </motion.h2>
        <motion.span 
          className="section__subtitle"  
          initial={{y:200, opacity:0}}
          whileInView={{y:0, opacity:1}}
          viewport={{once:true}}
          transition={{duration: 0.7, delay:0.75}}
        >
          Some of our Projects</motion.span>

     <Images />
   </section>
  )
}

export default Gallery